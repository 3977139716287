// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import './app/app.theme.scss';
@import './app/blades/auth/pages/authentication-page/auth.theme.scss';
@import './app/blades/explore/pages/explore-main/explore-main.theme.scss';
@import './app/blades/explore/components/explore-filter/explore-filter.theme.scss';
@import './app/blades/explore/components/explore-main/explore-deck-map-legend/explore-deck-map-legend.theme.scss';
@import './app/blades/explore/components/explore-poi-table/explore-poi-table.theme.scss';
@import './app/blades/explore/components/explore-main/geography-search-select/geography-search-select.theme.scss';
@import './app/blades/explore/components/explore-deep-dive/explore-deep-dive-configuration-controller/explore-deep-dive-configuration.theme.scss';
@import './app/blades/monitoring/components/dashboard/dashboard.component.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.legacy-typography-hierarchy(mat.define-typography-config());
@include mat.core();

/*
 *  This block is for modifying the typography 
 */

$custom-typography: mat.define-typography-config(
  $font-family: "Mulish, sans-serif",
    $headline-5: mat.define-typography-level($font-weight: 700,
      $font-size: 22px,
      $line-height: 30px,
      $letter-spacing: 0.02125em),
    $headline-6: mat.define-typography-level($font-weight: 500,
      $font-size: 16px,
      $line-height: 24px,
      $letter-spacing: 0.02125em),
    $body-1: mat.define-typography-level($font-weight: 400,
      $font-size: 15px,
      $line-height: 24px,
      $letter-spacing: normal),
    $body-2: mat.define-typography-level($font-weight: 400,
      $font-size: 14px,
      $line-height: 20px,
      $letter-spacing: normal),
    $button: mat.define-typography-level(
      $font-size: 13px,
      $font-weight: 500,
      $line-height: 20px,
      $letter-spacing: normal
    )
);

@include mat.all-component-typographies($custom-typography);

$primary-color: (
  50 : #f8fbff,
  100 : #ecf6ff,
  200 : #e0f0ff,
  300 : #d4eaff,
  400 : #cae6ff,
  500 : #c1e1ff,
  600 : #bbddff,
  700 : #b3d9ff,
  800 : #abd5ff,
  900 : #9ecdff,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$accent-color: (
  50 : #edf6ff,
  100 : #d1e9ff,
  200 : #b3daff,
  300 : #94cbff,
  400 : #7dc0ff,
  500 : #66b5ff,
  600 : #5eaeff,
  700 : #53a5ff,
  800 : #499dff,
  900 : #388dff,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #e1edff,
  A700 : #c8deff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ux-primary: mat.define-palette($primary-color, 500);
$ux-accent: mat.define-palette($accent-color, 500, 300, 700);

// The warn palette is optional (defaults to red).
$ux-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$light-theme: mat.define-light-theme((color: (primary: $ux-primary,
        accent: $ux-accent,
        warn: $ux-warn),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($light-theme);
@include app-theme($light-theme);
@include auth-theme($light-theme);
@include explore-main-theme($light-theme);
@include explore-filter-theme($light-theme);
@include explore-deck-map-legend-theme($light-theme);
@include explore-poi-table-theme($light-theme);
@include geography-search-select-theme($light-theme);
@include explore-deep-dive-configuration-theme($light-theme);
@include dashboard-theme($light-theme);

/*
 * Uncomment this block to add a dark theme

$ux-dark-primary: mat-palette($primary-color, 500);
$ux-dark-accent: mat-palette($accent-color, 500, 300, 700);

$dark-theme: mat-dark-theme($ux-dark-primary, $ux-dark-accent, $ux-warn);

.alternative {
  @include angular-material-theme($dark-theme);
  @include app-theme($dark-theme);
  @include login-theme($dark-theme);
  @include explore-main-theme($dark-theme);
  @include explore-filter-theme($dark-theme);
  @include explore-deck-map-legend-theme($dark-theme);
  @include explore-poi-table-theme($dark-theme);
  @include geography-search-select-theme($dark-theme);
  @include explore-deep-dive-configuration-theme($dark-theme);
  $foreground: map-get($dark-theme, 'foreground');
  $text-color: mat-color($foreground, 'text');
  color: $text-color;
}

*/