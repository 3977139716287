// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin explore-deck-map-legend-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  
  $bgcolor: mat.get-color-from-palette($background, 'background');
  $text-color: mat.get-color-from-palette($foreground, 'text');
  $disabledtextcolor: mat.get-color-from-palette($foreground, 'disabled-text');
  $white: #fff;
  $primary-color: mat.get-color-from-palette($primary);
  $accent-color: mat.get-color-from-palette($accent);

  .legend-btn {
    background-color: $bgcolor;
  }
  
  .legend-btn:hover {
    background-color: darken($bgcolor, 10);
  }

  .sidebar-active {
    background-color: $bgcolor;
    color: $text-color;
    box-shadow: 3px 2px 5px rgba($bgcolor, .2);
  }

  .text-color {
    color: $text-color;
  }

  .legend-icon {
    color: $accent-color;
  }
}