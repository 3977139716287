// Import library functions for theme creation.
@use '@angular/material' as mat;

@mixin geography-search-select-theme($theme) {
  $accent: map-get($theme, accent);
  $background: map-get($theme, 'background');
  $bgcolor: mat.get-color-from-palette($background, 'background');
  $accent-color: mat.get-color-from-palette($accent);

  .custom-search {
    background-color: rgba($bgcolor, 0.5);
    transition: all .2s ease-in-out;
    
    &:hover, &:focus {
      border-color: $accent-color;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, .2);
    }

    &:focus {
      background-color: $bgcolor;
    }
  }

  .advanced-search {
    background-color: $bgcolor;
  }
  .advanced-search-icon.selected {
    color: $accent-color;
  }
  .advanced-search-icon:hover {
    color: $accent-color;
  }
}