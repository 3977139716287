@use '@angular/material' as mat;

@mixin explore-poi-table-theme($theme) {
  $background: map-get($theme, 'background');
  
  $bgcolor: mat.get-color-from-palette($background, 'background');

  .expandable-section {
    background-color: $bgcolor;

    .expand-btn {
      background-color: $bgcolor;
    }
  }

  .table {
    &-row {
      transition: all .1s ease-in-out;

      &:hover {
        background-color: darken($bgcolor, 2);
      }
    }
  }
}