// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin explore-main-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  
  $bgcolor: mat.get-color-from-palette($background, 'background');
  $text-color: mat.get-color-from-palette($foreground, 'text');

  .left-sidebar-content {
    background-color: darken($bgcolor, 2);
    min-height: calc(100% - 0rem);
  }
  .right-sidebar-content {
    background-color: darken($bgcolor, 2);
    min-height: 100%;
  }

  .filter-selector-container {
    background-color: darken($bgcolor, 2);
  }
}