/* VARIABLES  */
// This file contains extra colors needed
// which are not included in the main theme
$color-success: #05c254;
$color-warn: #f9a825;
$color-danger: #c62828;
$color-bg-grey: #F1F3F4;

// Other variables
$navbar-height: 3rem;
$left-sidebar-width: 16rem;
$right-sidebar-width: 30vw;
$legend-card-width: 13rem;

$primary-gradient: linear-gradient(270deg, rgba(210, 255, 229, 0.9) 0%, #C1E1FF 100%);
$primary-gradient-inverse: linear-gradient(90deg, rgba(210, 255, 229, 0.9) 0%, #C1E1FF 100%);
$primary-gradient-dimmed: linear-gradient(270deg, rgba(210, 255, 229, 0.5) 0%, #C1E1FFBB 100%);
$global-border-radius: 4px;
