// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin auth-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  
  $bgcolor: mat.get-color-from-palette($background, 'background');
  $text-color: mat.get-color-from-palette($foreground, 'text');

  .auth-page {
    .logo {
      background-color: #EDEDED;
    }

    &-form {
      
      .form-header {
        position: relative;

        .underline {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          width: 100%;
          background-color: mat.get-color-from-palette($primary);
        }
      }

    }
  }
}