// Import library functions for theme creation.
@use '@angular/material' as mat;
@import '../variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin app-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  $bgcolor: mat.get-color-from-palette($background, 'background');
  $text-color: mat.get-color-from-palette($foreground, 'text');
  $disabledtextcolor: mat.get-color-from-palette($foreground, 'disabled-text');
  $white: #fff;
  $primary-color: mat.get-color-from-palette($primary);
  $accent-color: mat.get-color-from-palette($accent);

  .mat-mdc-standard-chip {
    padding: 6px 8px !important;
    min-height: 24px !important;
    height: 24px !important;
  }

  .mdc-evolution-chip-set .mdc-evolution-chip {
    margin: 0 8px 0 0 !important;
  }

  .noUi-base .noUi-connects .noUi-connect {
    background: mat.get-color-from-palette($accent);
  }

  [disabled] .noUi-base .noUi-connects .noUi-connect {
    background: #B8B8B8
  }

  .noUi-horizontal {
    height: 13px !important;
  }

  .noUi-horizontal .noUi-handle {
    width: 20px !important;
    height: 20px !important;
    right: -10px !important;
  }

  .noUi-handle:after {
    left: 10px !important;
    top: 3px !important;
  }

  .noUi-handle:before {
    left: 7px !important;
    top: 3px !important;
  }

  .noUi-tooltip {
    font-size: 87.5% !important;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background: mat.get-color-from-palette($accent) !important;
  }

  .ng-select .ng-select-container {
    color: $text-color;
  }

  .ng-select .ng-select-container::after {
    border-bottom: thin solid $disabledtextcolor;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    color: $text-color;
  }

  .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: mat.get-color-from-palette($accent) !important;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: $disabledtextcolor;
  }

  .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
    color: mat.get-color-from-palette($accent) !important;
  }

  .ng-select.ng-select-focused .ng-select-container::after {
    border-color: mat.get-color-from-palette($accent) !important;
  }

  .ng-dropdown-panel {
    background: $bgcolor;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: $text-color;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: $disabledtextcolor;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background: lighten($bgcolor, 10);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    color: mat.get-color-from-palette($accent);
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background: mat.get-color-from-palette($accent);
    color: $white;
  }

  .ng-select .ng-clear-wrapper {
    color: $text-color;
  }

  .search-select .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 170px;
  }

  .mat-form-field-appearance-fill .mat-mdc-text-field-wrapper.mdc-text-field:not(.mdc-text-field--no-label) {
    background-color: $primary-color;
    border-radius: $global-border-radius !important;
    background-image: $primary-gradient !important;
  }
  
  .mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary {
    color: $text-color;
  }

  .mat-expansion-panel-header {
    color: $accent-color;
  }

  .custom-chip {
    background-image: $primary-gradient;
    border-radius: 16px;
    padding: 4px 8px;
    width: 88%;
  }

  .sidebar-right-toggle {
    background-color: $bgcolor;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--selectable:not(.mdc-evolution-chip--with-primary-icon) {
    margin-right: 0px !important;
  }
}