// Import library functions for theme creation.
@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin dashboard-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $background: map-get($theme, 'background');
    $foreground: map-get($theme, 'foreground');

    $bgcolor: mat.get-color-from-palette($background, 'background');
    $text-color: mat.get-color-from-palette($foreground, 'text');
    $disabledtextcolor: mat.get-color-from-palette($foreground, 'disabled-text');
    $white: #fff;

    $primary-color: mat.get-color-from-palette($primary);
    $accent-color: mat.get-color-from-palette($accent);

    ::selection {
        background: #4318FF !important;
    }

    .ant-picker-focused {
        box-shadow: 0 0 0 2px #9D9BF4 !important;
    }

    .ant-picker-focused,
    .ant-picker:hover {
        border-color: #4318FF !important;
    }

    .container {
        letter-spacing: -0.02em !important;
        padding-bottom: 1rem;
    }

    .legend-btn {
        background-color: $bgcolor;
    }

    .legend-btn:hover {
        background-color: darken($bgcolor, 10);
    }

    .sidebar-active {
        background-color: $bgcolor;
        color: $text-color;
        box-shadow: 3px 2px 5px rgba($bgcolor, .2);
    }

    .text-color {
        color: $text-color;
    }

    .legend-icon {
        color: $accent-color;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab:hover {
        color: #4318FF;
    }

    .ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
        height: 5px;
    }

    .ant-tabs-ink-bar,
    .ant-picker-range .ant-picker-active-bar {
        background: #4318FF;
    }

    .mat-icon.filterIcon {
        color: #4318FF;
    }

    h1,
    .heading {
        font-size: 34px;
        color: #2B3674;
        font-weight: 700;
        padding-left: 10px;
    }

    .ant-card-head-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #2B3674;
        font-weight: 700;
    }

    .ant-card-extra .mat-icon {
        color: lightgray;
        cursor: pointer;
    }

    p {
        color: #707EAE;
        font-weight: 400;
    }

    .ant-row {
        &.head {
            margin: 0 0 1.7rem 0 !important;
        }

        margin: 1.7rem 0 !important;
    }

    .flex-column {
        display: flex !important;
        flex-direction: column !important;
    }

    .flex {
        display: flex !important;
        align-items: center !important;
        justify-content: space-around !important;
    }

    .ant-card-head-title {
        padding-bottom: 0 !important;
    }

    .ant-card-body {
        padding: 15px 24px;
    }

    .profile-card {
        .flex-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: inherit;
        }

        .section-light {
            color: #A3AED0;
            font-weight: 500;
            font-size: 16px;
        }

        .section-dark {
            color: #1B2559;
            font-weight: 700;
            font-size: 18px;
        }

        .flex-column {
            width: 13rem;

            &.small {
                width: 7rem;
            }
        }
    }

    .activity-card,
    .graph-card {
        border-radius: 20px !important;

        .ant-card-head {
            border-bottom: none !important;
        }

        .description {
            color: #A3AED0 !important;
        }

        .section-light {
            color: #A3AED0;
            font-weight: 500;
            font-size: 16px;
        }

        .section-dark {
            color: #1B2559;
            font-weight: 700;
            font-size: 18px;
        }
    }

    .activity-card-dark {
        border-radius: 20px !important;
        background-color: #4318FF !important;

        .section-light {
            color: white;
            font-weight: 500;
            font-size: 16px;
        }

        .section-dark {
            color: white;
            font-weight: 700;
            font-size: 18px;
        }
    }

    .fos-catchment-section {
        padding-inline: 8px;
        height: 35rem;
    }
}

.breadcrumbs {
    list-style: none;
    margin: 0;
    padding: 0;
}

.breadcrumbs li,
h3 {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    float: left;
    font-family: Mulish, sans-serif;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 20px;
    color: hsl(0, 0%, 30%);
    cursor: pointer;
}

.active {
    color: #1890ff !important;
}

.breadcrumbs li a {
    display: block;
    padding: 0 40px 0 0px;
    color: hsl(0, 0%, 30%);
    text-decoration: none;
    height: 20px;
    position: relative;
    perspective: 700px;
}

.breadcrumbs li a:after {
    content: "";
    width: 15px;
    height: 15px;
    border-color: #333;
    border-style: solid;
    border-width: 0.5px 0.5px 0 0;

    -webkit-backface-visibility: hidden;
    outline: 0.5px solid transparent;

    position: absolute;
    right: 20px;
    -webkit-transform: rotateZ(45deg) skew(10deg, 10deg);
    -moz-transform: rotateZ(45deg) skew(10deg, 10deg);
    -ms-transform: rotateZ(45deg) skew(10deg, 10deg);
    transform: rotateZ(45deg) skew(10deg, 10deg);
}

.business-trends-chart .chart-legend {
    margin-top: -20px;
}

.aging-chart svg {
    margin-bottom: -20px;
}

.lead-coversion-timeline .ngx-charts {
    margin-left: -30px;
}

.line-graph .ngx-charts {
    overflow: visible
}

.branch-performance-export-btn {
    color: #2E83F9;
    border: 2px solid #2E83F9;
    background-color: #fff;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    max-width: 95px;
    margin-right: 10px;
}


.branch-performance-export-p-tag {
    margin-top: 10px;
    font-weight: 600 !important;
}

.header_export {
    display: flex;
    justify-content: space-between;
}

.fos-catchment-section {
    .lead-catchment-container {
        height: 100%;
        display: flex;
        border-radius: 20px;

        .legend-header-container {
            display: flex;
            justify-content: flex-end;
        }

        .catchment-map {
            flex: 1;
        }

        .tab-container {
            height: 100%;
        }
    }

    ::ng-deep .ant-tabs {
        .ant-tabs-tab {
            padding-inline: 10px;
        }

        .ant-tabs-tab+.ant-tabs-tab {
            margin: 0 0 0 10px;
        }

        .ant-tabs-content {
            height: 100%;
        }
    }

    ::ng-deep .lead-catchment-container .ant-card-body {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .leads-report-export-btn {
        color: #2E83F9;
        border: 2px solid #2E83F9;
        background-color: #fff;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        max-width: 95px;
    }

    .lead-report-export-p-tag {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 600;
    }

    .lead-report-export-img {
        margin-right: 5px;
        margin-bottom: 5px;
    }
}